"use client";

import { useCallback, useEffect } from "react";
import { services } from "@/services";
import { useAppConfigsStore } from "@/stores/useAppConfigsStore";

export default function BackgroundDataPrefetch() {
  const setAppConfig = useAppConfigsStore((state) => state.setAppConfig);

  const fetchAppConfig = useCallback(async () => {
    try {
      const res: any = await services.configsApi.getConfigs();

      if (res.data) {
        setAppConfig(res.data.provinces, res.data.requestTypes);
      }
    } catch (error) {}
  }, [setAppConfig]);

  useEffect(() => {
    fetchAppConfig();
  }, [fetchAppConfig]);

  return null;
}
